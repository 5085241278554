import React from 'react';
import { ProgressBar } from 'react-bootstrap';

function SkillSection({ title, skills, levels }) {
    // Inline style for level-bar
    const levelStyle = {
        width: '25%',
    }

    return (
        <section className="mb-4">
            <h3>{title}</h3>
            {/* Loop through the skillsData array and generate Progress components */}
            {skills && skills.map((data, index) => (
                <ProgressBar 
                    key={index}
                    now={data.percentage}
                    label={data.skill}
                    className='text-truncate'
                />
            ))}
            {levels &&
                <div className='level'>
                    <div className='level-bar text-truncate' style={levelStyle}>
                        <span className='level-text'>Beginner</span>
                    </div>
                    <div className="level-bar text-truncate" style={levelStyle}>
                        <span className='level-text'>Intermediate</span>
                    </div>
                    <div className="level-bar text-truncate" style={levelStyle}>
                        <span className='level-text'>Advanced</span>
                    </div>
                    <div className="level-bar text-truncate" style={levelStyle}>
                        <span className='level-text'>Superior</span>
                    </div>
                </div>
            }
        </section>
    )
}

export default SkillSection;