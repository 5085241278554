const workData = [
    {
        company: 'Contraste',
        companyLink: 'https://www.contraste.com',
        fromDate: '01/04/2010',
        endDate: 'now',
        roles: [
            {
                function: "Web Developer full stack & Analyst",
                fromYear: '2020',
                endYear: 'now',
                items: [
                    { itemText: "Development of new features in PHP in an application that ensures the administration and accounting for the Brussels schools." },
                    { itemText: "Development of a web application dedicated to the replenishment of over 3,100 stores in Symfony." },
                    { itemText: "Implementation of APIs (RESTfull API) such as First-ID, Froomle, Panorapresse and Saooti." },
                    { itemText: "Migrate articles to a new archive system using a Python script." },
                    { itemText: "Implementing security features such as reCAPTCHA, blocking of users after too many wrong passwords, securely process a reset password, …" },
                    { itemText: "Development of imports/exports of various file types such as xlsx, csv and xml in PHP or Python." },
                    { itemText: "Follow-up of support tickets." },
                    { itemText: "Deploying new releases on the server with Git." },
                ]
            },
            {
                function: "Application support engineer",
                fromYear: '2017',
                endYear: '2020',
                items: [
                    { itemText: "Worked as an Ops agent in the ING Financial Market department following the Agile methodology approach." },
                    { itemText: "Incident management : ensuring the availability of several trading applications and systems for the ING Group all over the world." },
                    { itemText: "Change management : follow up on changes which could impact operations. Deploying new releases on the system using GitLab." },
                    { itemText: "IT Risk management : maintain the application documents with the necessary evidences for SOX compliance and ING defined standards related to IT Risk." },
                    { itemText: "Present documents for approval to ING IT Risk Management." }
                ]
            },
            {
                function: "Data analyst / reporting",
                fromYear: '2012',
                endYear: '2017',
                items: [
                    { itemText: "Generate daily, weekly & monthly KPI reports for different Amsit clients and analyze the out of SLA tickets." },
                    { itemText: "Create Excel trend analyses (dashboard + pivot tables) to visualize the evolution of different incoming problems at the service desk." },
                    { itemText: "Analyze data from different sources. (HP Service Center, Avaya, Cisco, Outlook...)" },
                    { itemText: "Presented the Amsit format of reporting to a customer at Luxembourg for the start-up of a new service desk and helped define the different KPI and PI." },
                ]
            },
            {
                function: "Service Desk operator",
                fromYear: '2010',
                endYear: '2012',
                items: [
                    { itemText: "1st line service desk call handling and follow up." },
                    { itemText: "Resolving incidents as a 2nd line handler." },
                    { itemText: "Alarm handling and follow up of the self’ banks." },
                    { itemText: "Monitoring of ING central systems." },
                    { itemText: "Software support for technicians." },
                ]
            }
        ]
    },
    {
        company: 'OAOT',
        fromDate: '01/02/2005',
        endDate: '31/03/2010',
        roles: [
            {
                function: "Datacenter Operator",
                fromYear: '2005',
                endYear: '2010',
                items: [
                    { itemText: "Working in a team environment 24/7." },
                    { itemText: "Hands & eyes with the customer at different datacenter locations." },
                    { itemText: "1st line support technician for technical and backup issues." },
                    { itemText: "Solving tickets in an international support environment." },
                ]
            }
        ]
    },
    {
        company: 'Administrative training with internship',
        companyLink: 'https://www.embuildvlaanderen.be/',
        fromDate: '05/04/2004',
        endDate: '12/11/2004'
    },
    {
        company: 'Confederatie Bouw',
        companyLink: 'https://www.embuildvlaanderen.be/',
        fromDate: '01/10/2002',
        endDate: '30/09/2003',
        roles: [
            {
                function: "Operator IT Departement",
                fromYear: '2002',
                endYear: '2003'
            }
        ]
    }
];
  
export default workData;