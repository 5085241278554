import React, { useState } from 'react';
import { Card, Form, ListGroup } from 'react-bootstrap';
import Link from './Link.js';

function WorkExperience({ workData }) {
    // Inline style for level-bar
    const levelStyle = {
        width: '25%',
    }

    // Set the initial year to the current year (2024)
    const [selectedYear, setSelectedYear] = useState(2024);

    // Event handler to update the year when sliding the range input
    const handleYearChange = (e) => {
        setSelectedYear(Number(e.target.value));
    };

    // Function to filter workItems based on selected year
    const filterWorkItemsByYear = (workItem, year) => {
        const formatDate = (dateStr) => {
            if (dateStr === 'now') return new Date().getFullYear(); // handle 'now'
            return parseInt(dateStr.split('/')[2]); // extract year from DD/MM/YYYY
        };

        const workItemStartYear = formatDate(workItem.fromDate);
        const workItemEndYear = formatDate(workItem.endDate);

        // Return true if workItem spans the selected year
        return workItemStartYear <= year && workItemEndYear >= year;
    };

    // Function to check if a role is within the selected year range
    const filterRoleByYear = (role, year) => {
        const roleStartYear = parseInt(role.fromYear);
        const roleEndYear = role.endYear === 'now' ? 2024 : parseInt(role.endYear);
        return roleStartYear <= year && roleEndYear >= year;
    };

    return (
        <Card className='mt-4 mb-4 mx-auto'>
            <Card.Header as='h2'>
                Work Experience
            </Card.Header>
            <Card.Body>
                <div className="row">
                    <div className="col-12 col-md-8 mx-auto">
                        <Form.Label>Year: {selectedYear}</Form.Label>
                        <Form.Range 
                            min="2002"
                            max="2024"
                            value={selectedYear}
                            onChange={handleYearChange} // handle slider movement
                        />
                        <div className='level'>
                            <div className='level-bar text-truncate' style={levelStyle}>
                                <span className='year-text'>2002 - 2007</span>
                            </div>
                            <div className='level-bar text-truncate' style={levelStyle}>
                                <span className='year-text'>2007 - 2013</span>
                            </div>
                            <div className='level-bar text-truncate' style={levelStyle}>
                                <span className='year-text'>2013 - 2018</span>
                            </div>
                            <div className='level-bar text-truncate' style={levelStyle}>
                                <span className='year-text'>2019 - now</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <ListGroup>
                            {workData
                                .filter((workItem) => filterWorkItemsByYear(workItem, selectedYear)) // Filter based on year
                                .map((workItem, index) => (
                                    <ListGroup.Item key={index}>
                                        {/* This h3 (workItem) will always be visible if the workItem is within the year range */}
                                        <h3>
                                            <Link 
                                                url={workItem.companyLink}
                                                text={workItem.company}
                                            />
                                            <small> {workItem.fromDate} - {workItem.endDate}</small>
                                        </h3>
                                        {/* Filter roles based on the selected year */}
                                        {Array.isArray(workItem.roles) && workItem.roles
                                            .filter((role) => filterRoleByYear(role, selectedYear)) // Only show roles within year range
                                            .map((role, roleIndex) => (
                                            <div key={roleIndex}>
                                                <h4 className='pt-2'>
                                                    {role.function}
                                                    <small> {role.fromYear} - {role.endYear}</small>
                                                </h4>
                                                <ul>
                                                {/* Check if role.items is an array before mapping over items */}
                                                {Array.isArray(role.items) &&
                                                role.items.map((item, itemIndex) => (
                                                    <li className='pb-2 pb-md-1' key={itemIndex}>{item.itemText}</li>
                                                ))}
                                                </ul>
                                            </div>
                                        ))}
                                    </ListGroup.Item>
                                ))
                            }
                        </ListGroup>
                    </div>
                </div>
            </Card.Body>
        </Card>
    )
}

export default WorkExperience;