import React from 'react';
import Skills from './components/Skills';
import WorkExperience from './components/WorkExperience';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import skillsData from './data/skillsData';
import workData from './data/workData';

function RightColumn() {
    return (
        <div className="container-right col-xs-12 col-md-10">
            <Tabs
                defaultActiveKey="skills"
                id="main-nav"
                className="mt-4 mt-md-1 mb-2"
            >
                <Tab eventKey="skills" title="Skills">
                    <Skills 
                        skillsData={skillsData}
                    />
                </Tab>
                <Tab eventKey="workExperience" title="Work Experience">
                    <WorkExperience 
                        workData={workData}
                    />
                </Tab>
            </Tabs>
        </div>
    )
}

export default RightColumn;