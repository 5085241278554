const skillsData = [
    {
        title: 'Languages',
        column: 1,
        skills: [
            { skill: "DUTCH", percentage: 100 },
            { skill: "FRENCH", percentage: 90 },
            { skill: "ENGLISH", percentage: 80 }
        ]
    },
    {
        title: 'Front-end development',
        column: 1,
        levels: true,
        skills: [
            { skill: "HTML / XML", percentage:  100 },
            { skill: "CSS / SASS", percentage: 55 },
            { skill: "JavaScript / AJAX", percentage: 50 }
        ]
    },
    {
        title: 'JavaScript libraries',
        column: 1,
        levels: true,
        skills: [
            { skill: "JQuery", percentage: 50 },
            { skill: "React", percentage: 35 }
        ]
    },
    {
        title: 'CSS frameworks',
        column: 1,
        levels: true,
        skills: [
            { skill: "Bootstrap", percentage: 80 }
        ]
    },
    {
        title: 'Operating systems',
        column: 2,
        levels: true,
        skills: [
            { skill: "Windows", percentage:  70 },
            { skill: "Linux / Unix", percentage: 60 }
        ]
    },
    {
        title: 'Back-end development',
        column: 2,
        levels: true,
        skills: [
            { skill: "PHP", percentage:  80 },
            { skill: "RESTful API", percentage: 55 },
            { skill: "Python", percentage: 60 }
        ]
    },
    {
        title: 'PHP frameworks',
        column: 2,
        levels: true,
        skills: [
            { skill: "Laravel", percentage: 20 },
            { skill: "Symfony", percentage: 50 }
        ]
    },
    {
        title: 'Content management systems',
        column: 2,
        levels: true,
        skills: [
            { skill: "Drupal 7", percentage: 60 },
            { skill: "WordPress 6", percentage: 40 },
        ]
    },
    {
        title: 'Databases',
        column: 3,
        levels: true,
        skills: [
            { skill: "MySQL / MariaDB", percentage:  85 },
            { skill: "MS SQL", percentage: 65 }
        ]
    },
    {
        title: 'Software testing',
        column: 3,
        skills: [
            { skill: "ISTQB", percentage: 40 }
        ]
    },
    {
        title: 'Other',
        column: 3,
        skills: [
            { skill: "Git / GitHub / GitLab", percentage: 80 },
            { skill: "Docker", percentage: 70 },
            { skill: "Confluence", percentage: 70 },
            { skill: "MS Excel / Google Sheets", percentage:  90 },
            { skill: "MS Word / Google Docs", percentage: 90 },
            { skill: "Agile Scrum", percentage: 80 },
            { skill: "Incident & release Management", percentage: 90 }
        ]
    }
];
  
export default skillsData;