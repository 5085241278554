import React from 'react';
import { Image } from 'react-bootstrap';
import photoMe from './assets/images/photo_me.png';
import { FaLinkedin } from "react-icons/fa";
import QRCode from "react-qr-code";

function LeftColumn() {
    return (
        <div className='container-left col-xs-12 col-md-2 pt-4 text-center'>
            <div className='row'>
                <div className='col-12'>
                    <Image 
                        src={photoMe}
                        fluid
                    />
                    <br /><br />
                    <h1 className='text-uppercase'>Jimmy Bauduin</h1>
                    <p className='text-center'>
                        Web Developer Full stack
                        <br /><br />
                        More than 10 years of experience as <br />Analyst & Web Developer Full stack
                        <br /><br />
                        <a href="https://www.linkedin.com/in/jimmy-bauduin-292022121/" rel="noreferrer" target="_blank">
                            <FaLinkedin 
                                size={35}
                            />
                        </a>
                    </p>
                </div>
                <div className='col-12 mt-5'>
                    <p>
                        Open on mobile <br />or share this page
                    </p>
                    <QRCode
                        style={{ height: "auto", maxWidth: "25%", width: "25%" }}
                        value={'http://cv.jimmybauduin.com/'}
                    />
                </div>
            </div>
        </div>
    )
}

export default LeftColumn;