import React, { useState } from 'react';
import { Card, Form, InputGroup } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';
import SkillSection from './SkillsSection';

function Skills({ skillsData }) {
    // State for the search term
    const [searchTerm, setSearchTerm] = useState('');

    // Handle the search input change
    const handleSearch = (e) => {
        setSearchTerm(e.target.value.toLowerCase());
    };

    // Handle clearing the search input
    const handleClear = () => {
        setSearchTerm('');
    };

    // Filter skills based on the search term
    const filteredSkillsData = skillsData.filter((category) => {
        // Check if the title matches the search term
        const matchTitle = category.title.toLowerCase().includes(searchTerm);

        // Check if any of the skills within the category match the search term
        const matchSkill = category.skills.some(skill =>
            skill.skill.toLowerCase().includes(searchTerm)
        );

        // Return true if either the title or any skill within it matches
        return matchTitle || matchSkill;
    });

    // Split filtered data into three columns based on the 'column' property
    const columns = [[], [], []];  // Initialize three columns

    filteredSkillsData.forEach((category) => {
        const columnIndex = category.column - 1;  // use the 'column' property
        columns[columnIndex].push(
            <SkillSection 
                key={category.title}
                title={category.title}
                skills={category.skills}
                levels={category.levels || false}  // default levels to false if not defined
            />
        );
    });

    return (
        <Card className='mt-4 mx-auto'>
            <Card.Header as='h2'>
                Skills
            </Card.Header>
            <Card.Body>
                <div className='row'>
                    <div className="col-12 col-md-6 mx-auto">
                        <Form>
                            <InputGroup>
                                <Form.Control 
                                    type="text" 
                                    placeholder="Search" 
                                    value={searchTerm}
                                    onChange={handleSearch} 
                                />
                                <InputGroup.Text
                                    onClick={handleClear}
                                    style={{ cursor: 'pointer', backgroundColor: 'transparent', border: 'none' }}
                                >
                                    <AiOutlineClose />
                                </InputGroup.Text>
                            </InputGroup>
                        </Form>
                    </div>
                </div>
                <div className='row'>
                    {/* Render each column */}
                    {columns.map((column, index) => (
                        <div className='col-12 col-lg-4 pt-4' key={index}>
                            {column}
                        </div>
                    ))}
                </div>
            </Card.Body>
        </Card>
    )
}

export default Skills;