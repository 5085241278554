import React from 'react';

function Link({ url, text }) {
    if (url) {
        return (
            <a href={url} rel="noreferrer" target="_blank">
                {text}
            </a>
        )
    }

    return (
        <span>
            {text}
        </span>
    );
}

export default Link;