import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/scss/main.scss'
import LeftColumn from './LeftColumn';
import RightColumn from './RightColumn';

function App() {
  return (
    <div className="container-fluid">
      <div className="row">
        <LeftColumn />
        <RightColumn />
      </div>
    </div>
  );
}

export default App;
